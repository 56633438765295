@use "sass:math";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$font_family_icons: "Font Awesome 6 Free";

@function strip-unit($value) {
    @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-type($max-font-size:24px, $min-font-size:16px, $min-vw:320px, $max-vw:1200px) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin font_xl {
    @include fluid-type(32px, 20px);
}

@mixin font_lg {
    @include fluid-type(24px, 18px);
}

@mixin font_md {
    @include fluid-type(20px, 16px);
}

@mixin font_sm {
    @include fluid-type(16px, 14px);
}

@mixin font_xs {
    @include fluid-type(14px, 12px);
}
