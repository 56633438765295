@import './../../../node_modules/bootstrap/scss/functions';
@import './../../../node_modules/bootstrap/scss/variables';
@import './../../../node_modules/bootstrap/scss/mixins';

// main colors
$color_primary: #3C4B66;
$color_secondary: #F8C300;
$color_third: #F87300;
$color_fourth: #D7E2FC;
$color-fifth: #FFBB01;
$color_inactive: #E4E4E4;
$color_inactive_secondary: #D4D4D4;
$color_separator: #707070;
$color_separator_light: #ccc;
$color-info: #88B9E1;

// hovers
$color_hover_primary: #257cc0;

// focus
$color_primary_focus: #ffcc15;

// with opacity
$color_secondary_transparent: #ffcc1557;
$color_primary_transparent: rgba(197, 197, 197, 0.28);

// text
$color_text: #fff;
$color_text_invert: #000;
$color_alert_text: #434343;
$color_link_text: #5078BE;
$color_inactive_text: #9CA0A1;
$color_date: #193E80;
$messages_background: #f1f2f2;
$highlight: #6612BC;

$color_input_cv: #f2f3f3;
$color_input_auth: #F5F5F5;
$color_input_auth_active: #fff;

// tab colors default
$color_tab_default_bg: $color_primary;
$color_tab_default_text: $color_text;
$color_tab_orange_bg: $color_secondary;
$color_tab_orange_text: $color_primary;

$chat_conversation_selected: #FFFAE6;
$chat_message_balloon_others: #fff;
$color_new_date: #5a5a5a;

// buttons
$add_more_fields: #4A965E;

// accordion colors
$color_accordion_info_default: $color_input_auth;
$color_accordion_info_secondary: $color_inactive;
$color_accordion_exercise_default: #d6dbed;
$color_accordion_exercise_secondary: #c7cfe4;
$color_accordion_additional_default: #d8e9dd;
$color_accordion_additional_secondary: #c9e6d2;
$color_accordion_break_default: #fee1c9;
$color_accordion_break_secondary: #fdc391;
