.standard-form {
    .mat-form-field,
    .mat-mdc-form-field {
        width: 100%;
    }

    .is-invalid .mat-input-element {
        border: 1px solid $danger !important;
    }

    .mat-mdc-text-field-wrapper,
    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-mdc-form-field-flex,
        .mat-form-field-flex {
            padding: 0;
            background-color: rgba(204, 204, 204, 0);

            .mat-mdc-form-field-infix,
            .mat-form-field-infix {
                border-top-width: 0;
                padding: 0;
            }

            .mat-mdc-form-field-appearance-fill,
            .mat-form-field-appearance-fill {
                padding: 0;
            }

            .mat-mdc-form-field-suffix,
            .mat-form-field-suffix {
                position: absolute;
                right: 0;
                transform: translateY(-50%);
                top: 50%;
            }

            input {
                color: $color_text_invert;
                background-color: rgba(156, 160, 161, 0.13);
                border-radius: 12px;
                box-shadow: 0px 3px 6px #00000029;
                border: none;
                padding: 10px 20px;
                @include font_sm;
                margin: 10px 0;
                line-height: 24px;
                font-family: 'Roboto', sans-serif;
                border: none;
                box-sizing: border-box;

                &:disabled {
                    color: $color_inactive_text;
                }
            }
        }

        .mat-mdc-form-field-underline,
        .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-subscript-wrapper {
            display: none;
        }
    }
}

