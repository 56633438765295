@import '/src/assets/styles/fonts.scss';
@import '/src/assets/styles/colors.scss';

.mat-tab-group.mat-primary {
    .mat-tab-header {
        border: none;

        .mat-tab-list {
            display: flex;
        }

        .mat-tab-labels {
            border-bottom: 7px solid $color_inactive_secondary;
        }

        .mat-tab-label {
            background-color: $color_inactive;
            color: $color_inactive_text;
            border-radius: 20px 20px 0 0;
            margin: 0 0 5px 5px;
            position: relative;
            width: fit-content;
            opacity: 1;
            @include font_md;

            &:focus,
            &.mat-tab-label-active {
                background-color: $color_tab_default_bg;
                color: $color_tab_default_text;
            }

            .tab-header {
                background-color: $color_secondary;
            }
        }
    }

    .mat-ink-bar {
        visibility: visible;
        // border-bottom: 10px solid red;
        display: none;
    }

    // .mat-tab-body-wrapper {
    //     &,
    //     .mat-tab-body,
    //     .mat-tab-body .mat-tab-body-content {
    //         overflow: visible !important;
    //     }
    // }
}
