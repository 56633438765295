.main-content {
    margin-top: 0 !important;

    @media screen and (min-width: 992px) {
        margin-top: 35px !important;
    }
}

.primary-btn {
    background-color: $color_primary;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
    padding: 5px 10px;
    border: none;
    &.shadow {
        box-shadow: 0px 3px 6px #00000029;
    }
    &:hover {
        color: #fff;
        background-color: $color_hover_primary;
    }
    &.xl {
        @include font_md;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 10px;

        i {
            font-size: 1.5em !important;
        }
    }
    &.secondary {
        background-color: $color_secondary;

        &:hover {
            background-color: $color_third;
        }
    }
}
.secondary-btn {
    background-color: $color_text;
    color: $color_primary;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
    padding: 5px 10px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    &.lg {
        @include font_sm;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: fit-content;
        i {
            font-size: 1.8em;
        }
    }
    &:hover {
        color: #fff;
        background-color: $color_hover_primary;
    }
}
.btn-link {
    padding: 0;
    margin-bottom: 16px;
    color: $color_third;
    text-decoration: none!important;
    &:hover {
        color: $color_hover_primary!important;
    }
}
.transparent-btn {
    background-color:transparent;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s;
    padding: 5px 25px;
    border: 1px solid #fff;
    &.shadow {
        box-shadow: 0px 3px 6px #00000029;
    }
    &:hover {
        color: #fff;
        background-color: $color_hover_primary;
    }
    &.xl {
        @include font_md;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 10px;


        i {
            font-size: 1.5em !important;
        }
    }
    &.secondary {
        background-color: $color_secondary;

        &:hover {
            background-color: $color_third;
        }
    }
}
.separator {
    height: 0;
    margin-top: 7px;

    span {
        display: inline-block;
        width: 100px;
        height: 9px;
        border-radius: 10px;
        background-color: $color_primary;
    }

    i {
        font-size: 36px;
    }

    &.secondary {
        span {
            background-color: $color_secondary;
        }

        i {
            color: $color_secondary;
        }
    }

    &.third {
        span {
            background-color: $color_third;
        }

        i {
            color: $color_third;
        }
    }
}

/*
    Side Component
*/
.side-content {
    background-color: rgba(156, 160, 161, 0.11);
    padding: 30px 0;
    border-radius: 0 0 0 30px;

    &:empty {
        background: transparent !important;
    }

    @media screen and (max-width: 1199px) {
        background-color: rgba(156, 160, 161, 0);
    }

    & > div {
        border-bottom: 1px solid $color_separator_light;

        @media screen and (max-width: 1199px) {
            border-bottom: none;
        }
    }

    & > div:last-child {
        border-bottom: none;
    }

    h3 {
        @include font_lg;
        font-weight: bold;
        color: $color_primary;

        .separator {
            span {
                width: 60px;
                height: 6px;
            }

            i {
                font-size: 30px;
            }
        }
    }

    .side-icon {
        padding-bottom: 35px;
    }
}

.video-wrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    video,
    iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        box-shadow: 0 0 2px black;
        z-index: 1;
    }
}


.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #ffffff;
    background: transparent!important;
    border-color: transparent!important;
}
.danger {
    color: $color_third
}
.warning {
    color: $color-fifth;
}
.btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none!important;
}

// checkboxes

.container-checkbox {
    white-space: nowrap;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include font_sm;
    color: $color_primary;
}

.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #88B9E1;
    border-radius: 5px;
    opacity: 1;
}
.container-checkbox input:checked~.checkmark {
    background-color: #2196F3;
}
.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox input:checked~.checkmark:after {
    display: block;
}
.container-checkbox .checkmark:after {
    left: 6px;
    top: 0px;
    width: 10px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-control:focus {
    box-shadow: none!important;
}
.cross-validation-error {
    color: $color_third!important;
    display: block;
}

input.is-invalid,
select.is-invalid,
textarea.is-invalid {
    border: 1px solid #dc3545 !important;
}

.p-caroucel-box{
    padding: 10px 0px;
    height: 100%;
}

@media screen and (max-width: 981px) {
    .justify-sm-content-between {
        justify-content: space-between;
    }
    p-toast .p-toast {
        max-width: calc(100% - 40px);
    }
}

::ng-deep .p-caroucel-box {
    height: 100%!important;
}

.dynamic-content {
    table {
        width: 100%;

        h4{
            margin: 0;
            margin-top: 5px;
        }

        p{
            margin: 0;
            margin-top: 5px;
            @include font_sm;
        }

        span{
            margin: 0 !important;
            margin-top: 5px !important;
        }
    }

    img {
        max-width: 50% !important;
        display: block;
        border-radius: 4px;

        &:hover {
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        }
    }

    .materials {
        figure {
            .material-title {
                border: 2px dashed $color_primary_focus;
                color: $color_primary;
                @include font_sm;

                h3 {
                    color: $color_primary;
                    @include font_lg;
                    margin-bottom: 15px;
                }

                ul {
                    li::marker {
                        color: $color_secondary;
                    }
                }

                p {
                    i {
                        color: $color_secondary;
                    }

                    span {
                        padding-left: 10px;
                    }
                }
            }
        }

        .materials-content {
            .accordion-item {
                border: none;
            }

            .accordion-button {
                color: $color_primary;
                @include font_md;
                background-color: $messages_background;

                p,
                span {
                    @include font_sm;
                    padding: 15px 25px;
                    line-height: 1rem;
                    i {
                        @include font_xs;
                    }
                }

                span {
                    background-color: $color_inactive;
                }

                &:not(.collapsed) {
                    background-color: $color_inactive;
                    color: $color_primary;
                }

                &::after {
                    position: absolute;
                    right: 10px;
                }

                &.info {
                    background-color: $color_accordion_info_default;

                    span {
                        background-color: $color_accordion_info_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_info_default;
                    }
                }

                &.exercise {
                    background-color: $color_accordion_exercise_default;

                    span {
                        background-color: $color_accordion_exercise_secondary;
                    }
                    &:not(.collapsed) {
                        background-color: $color_accordion_exercise_default;
                    }
                }

                &.additional {
                    background-color: $color_accordion_additional_default;

                    span {
                        background-color: $color_accordion_additional_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_additional_default;
                    }
                }

                &.break {
                    background-color: $color_accordion_break_default;

                    span {
                        background-color: $color_accordion_break_secondary;
                    }

                    &:not(.collapsed) {
                        background-color: $color_accordion_break_default;
                    }
                }
            }

            .accordion-body {
                @include font_sm;
                color: $color_primary;

                h3 {
                    @include font_md;
                    font-weight: bold;
                    margin: 20px 0 0;
                }

                p {
                    text-indent: 30px;
                    line-height: 1.5em;
                }

                ul {
                    li::marker {
                        color: $color_secondary;
                    }
                }
            }
        }
    }
}

.dynamic-content-career-infobox {
    a {
        color: $color_secondary;
        transition: all 0.2s;
        text-decoration: none;

        &:hover {
            color: $color_text;
            text-decoration: underline;
        }
    }
}
