@import '/src/assets/styles/fonts';
@import './../../../node_modules/bootstrap/scss/functions';
@import './../../../node_modules/bootstrap/scss/mixins';
@import './../../../node_modules/bootstrap/scss/variables';

// bootstrap
.btn {
    border-radius: 0.5rem;

    &.btn-primary {
        @include button-variant($color_primary, $color_primary);
    }
    &.btn-info {
        @include button-variant($color-info, $color-info);
        color: $color_text;
        &.lg {
            @include font_lg;
            font-weight: bold;
            padding: 5px 25px;
        }
    }
}

.standard-form {
    label {
        @include font_sm;
        font-weight: normal;

        &.required:after {
            content: "*";
            color: $color_third;
        }
    }
    .form-control {
        color: $color_text_invert;
        background-color: rgba(156, 160, 161, 0.13);
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
        border-radius: 12px;
        padding: 10px 20px;
        margin: 10px 0;
        @include font_sm;

        &:not(.is-invalid) {
            border: none;
        }

        &[disabled],
        &:disabled,
        &.disabled {
            color: darken($color_inactive, 50%);
            cursor: not-allowed;
        }
    }

    .is-invalid + .invalid-feedback {
        @include font_xs;
    }

    .is-invalid .ck-editor {
        border: 1px solid $danger;
    }
    .ck-editor {
        margin: 10px 0 !important;
        border-radius: 12px;
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
        border: none;

        .ck-toolbar {
            border: none;
            background: rgba(156, 160, 161, 0.13);
            border-radius: 12px 12px 0 0 !important;

            .ck-font-size-dropdown .ck-list__item button span {
                font-size: 1.0rem !important;
                line-height: normal;

            }
        }

        .ck-sticky-panel__content_sticky {
            background: #f5f5f5;

            .ck-toolbar {
                border-radius: 0 !important;
            }
        }

        .ck-editor__editable_inline {
            min-height: 20vh;
            border: none;
            border-radius: 12px !important;
            overflow: hidden;

            &.ck-focused {
                box-shadow: none !important;
                border: none !important;
            }
        }
    }
}

// add .order- classes up to 32
@for $i from 6 through 32 {
    .order-#{$i} {
        order: $i;
    }
    @media (min-width: 576px) {
        .order-sm-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 768px) {
        .order-md-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 992px) {
        .order-lg-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 1200px) {
        .order-xl-#{$i} {
            order: $i;
        }
    }
    @media (min-width: 1400px) {
        .order-xxl-#{$i} {
            order: $i;
        }
    }
}

.search-field-bar {
    z-index: 1;

    &.form-control {
        border-color: #C5C5C5;
        border-width: 1px;
        border-radius: 12px !important;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.24);
    }

    input {
        border: 0;
        background-color: transparent;
        padding: 9px 10px;
        border-radius: 12px !important;

        &:focus {
            box-shadow: none;
        }
    }

    .btn {
        color: #9CA0A1;
        border: 0 !important;
        position: relative;
        background-color: transparent !important;

        &:hover,
        &:active {
            color: $color_text_invert;
        }

        &.submit {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            width: 48px;
            text-align: center;
        }
    }
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: $color_primary!important;
}
