
p-dropdown {
    margin: 10px 0;
    display: block;

    .p-dropdown,
    .p-dropdown.p-focus {
        border: none;
        background-color: rgba(156, 160, 161, 0.13);
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
        border-radius: 12px;
    }

    &.is-invalid {
        border: 1px solid #dc3545;
        border-radius: 12px;
    }
}


p-multiselect {
    margin: 10px 0;

    &.is-invalid {
        border: 1px solid #dc3545;
        border-radius: 12px;
    }

    .p-multiselect {
        width: 100%;

        height: 44px;
        border: 0;
        color: #000;
        border-radius: 12px;
        color: $color_text_invert;
        background-color: rgba(156, 160, 161, 0.13);
        box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);

        .p-multiselect-label {
            padding-left: 4px !important;
        }

        .p-multiselect-token {
            border-radius: 10px !important;
            background: #ddd !important;
            border: 1px solid #bbb !important;
            color: black !important;
            margin-right: 4px;
            transition: all 0.2s;

            &:hover {
                background: #d0d0d0 !important;
            }

            &:last-child {
                margin-right: 0;
            }

            .p-multiselect-token-label ~ .p-icon-wrapper {
                color: #dc3545;
            }

            .p-multiselect-token-label ~ .p-icon-wrapper:hover {
                color: #fd5364 !important;
            }
        }
    }
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0.75rem 0.5rem 0.25rem;
}

p-calendar {
    .p-calendar {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .p-inputtext {
            @include font_sm;
            width: auto;
            color: #000;
            border: 0;
            background-color: rgba(156, 160, 161, 0.13);
            box-shadow: 0px 3px 6px hsla(0, 0%, 0%, 0.161);
            border-radius: 12px;
            padding: 10px 20px;
            margin: 10px 0;
            width: 90%;
            font-weight: 400;
            line-height: 1.5;

            &:hover, &:focus {
                cursor: pointer;
            }
        }

        .p-button {
            color: gray;
            background-color: transparent;
            outline: none !important;
            border: none;
            position: absolute;
            right: 15px;
            width: 10%;

            .p-icon {
                width: 1.3rem;
                height: 1.3rem;
            }

            &:active {
            box-shadow: none !important;;
            }

            &:hover, &:focus {
            border: none !important;;
            box-shadow: none;
            background-color: transparent;
            }
        }

        &:hover {
            .p-inputtext + .p-button {
                color: #aaa;
                cursor: pointer;
            }
        }

        .p-inputtext:enabled:focus {
            box-shadow: none;
        }

        &.p-calendar-w-btn {
            .p-inputtext {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }

            .p-button-icon-only {
                right: 12px;
                width: 32px;
                height: 32px;
                padding: 0;

                calendaricon svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &.is-invalid .p-calendar.p-calendar-w-btn .p-button-icon-only {
        display: none !important;
    }
}

p-breadcrumb .p-breadcrumb ul {
    white-space: nowrap;
}
