
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$academy-primary: mat.define-palette(mat.$indigo-palette);
$academy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$academy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$academy-theme: mat.define-light-theme((
  color: (
    primary: $academy-primary,
    accent: $academy-accent,
    warn: $academy-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($academy-theme);

// PrimeNG
@import "./../node_modules/primeicons/primeicons.css";
@import "./../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "./../node_modules/primeng/resources/primeng.min.css";

// Font AWesome
@import "./../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "./../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Common
@import "assets/styles/icons";
@import "assets/styles/tabs";
@import "assets/styles/colors";
@import "assets/styles/shared";



// Bootstrap 5
@import "./../node_modules/bootstrap/scss/bootstrap";
@import "assets/styles/bootstrap";

@import "assets/styles/primeng";
@import "assets/styles/material";

html, body { height: 100%; }
body { margin: 0; font-family: "Roboto", "Helvetica Neue", sans-serif; }
a { text-decoration: none; }
