@font-face {
    font-family: 'fontello';
    src: url('../fonts/icons/fontello.eot?69549108');
    src: url('../fonts/icons/fontello.eot?69549108#iefix') format('embedded-opentype'),
         url('../fonts/icons/fontello.woff2?69549108') format('woff2'),
         url('../fonts/icons/fontello.woff?69549108') format('woff'),
         url('../fonts/icons/fontello.ttf?69549108') format('truetype'),
         url('../fonts/icons/fontello.svg?69549108#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//     @font-face {
//         font-family: 'fontello';
//         src: url('../font/icons/fontello.svg?69549108#fontello') format('svg');
//     }
// }

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    // margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    // margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.smaller[class^="icon-"],
.smaller[class^=" icon-"] {
    font-size: 0.75em;
}
.small[class^="icon-"],
.small[class^=" icon-"] {
    font-size: 0.876em;
}

.fw[class^="icon-"]:before,
.fw[class^=" icon-"]:before {
    text-align: center;
    width: 1.25rem;
}


.icon-down-open-big:before { content: '\e800'; } /* '' */
.icon-up-open-big:before { content: '\e801'; } /* '' */
.icon-left-open-big:before { content: '\e802'; } /* '' */
.icon-right-open-big:before { content: '\e803'; } /* '' */
.icon-left-open-mini:before { content: '\e804'; } /* '' */
.icon-right-open-mini:before { content: '\e805'; } /* '' */
.icon-graduation-cap:before { content: '\e806'; } /* '' */
.icon-envelope-note:before { content: '\e807'; } /* '' */
.icon-clipboard:before { content: '\e808'; } /* '' */
.icon-phone:before { content: '\e809'; } /* '' */
.icon-messenger:before { content: '\e80a'; } /* '' */
.icon-group:before { content: '\e80b'; } /* '' */
.icon-certificate:before { content: '\e80c'; } /* '' */
.icon-star-circle:before { content: '\e80d'; } /* '' */
.icon-video:before { content: '\e80e'; } /* '' */
.icon-medal:before { content: '\e80f'; } /* '' */
.icon-chat:before { content: '\e810'; } /* '' */
.icon-email:before { content: '\e811'; } /* '' */
.icon-padlock:before { content: '\e812'; } /* '' */
.icon-bell:before { content: '\e813'; } /* '' */
.icon-share:before { content: '\e814'; } /* '' */
.icon-sliders:before { content: '\e815'; } /* '' */
.icon-save:before { content: '\e816'; } /* '' */
.icon-pen:before { content: '\e817'; } /* '' */
.icon-academy-a:before { content: '\e818'; } /* '' */
.icon-bank:before { content: '\e819'; } /* '' */
.icon-paypal:before { content: '\e81a'; } /* '' */
.icon-credit-card:before { content: '\e81b'; } /* '' */
.icon-cash:before { content: '\e81c'; } /* '' */
.icon-fb:before { content: '\e81d'; } /* '' */
.icon-star:before { content: '\e81e'; } /* '' */
.icon-bookmark:before { content: '\e81f'; } /* '' */
.icon-house:before { content: '\e820'; } /* '' */
.icon-faq:before { content: '\e821'; } /* '' */
.icon-quote:before { content: '\e822'; } /* '' */
.icon-friends:before { content: '\e823'; } /* '' */
.icon-contacts:before { content: '\e824'; } /* '' */
.icon-handshake:before { content: '\e825'; } /* '' */
.icon-bubble:before { content: '\e826'; } /* '' */
.icon-quote-left:before { content: '\e827'; } /* '' */
.icon-quote-right:before { content: '\e828'; } /* '' */
.icon-academy:before { content: '\e829'; } /* '' */
.icon-job:before { content: '\e82a'; } /* '' */
.icon-location:before { content: '\e82b'; } /* '' */
.icon-job-reverse:before { content: '\e82c'; } /* '' */
.icon-calendar:before { content: '\e82d'; } /* '' */
.icon-quote-bubble:before { content: '\e82e'; } /* '' */
.icon-users-outline:before { content: '\e82f'; } /* '' */
.icon-user:before { content: '\e830'; } /* '' */
.icon-user-circle:before { content: '\e831'; } /* '' */
.icon-cv:before { content: '\e832'; } /* '' */
.icon-magnifier:before { content: '\e833'; } /* '' */
.icon-chat-mobile:before { content: '\e834'; } /* '' */
.icon-pencil:before { content: '\e835'; } /* '' */
.icon-arrow-up-blank:before { content: '\e836'; } /* '' */
.icon-checkbox:before { content: '\e837'; } /* '' */
.icon-spin5:before { content: '\e838'; } /* '' */
.icon-certifacate-icon:before { content: '\e839'; } /* '' */
.icon-suitcase-icon:before { content: '\e83b'; } /* '' */
.icon-card:before { content: '\e83c'; } /* '' */
.icon-file:before { content: '\e83d'; } /* '' */
.icon-createfolder:before { content: '\e83e'; } /* '' */
.icon-add-folder:before { content: '\e83f'; } /* '' */
.icon-close-comment-signt:before { content: '\e840'; } /* '' */
.icon-open-comment-signt:before { content: '\e841'; } /* '' */
.icon-arrow-up-fill:before { content: '\e842'; } /* '' */
.icon-info-icon:before { content: '\e843'; } /* '' */
.icon-magnifier-1:before { content: '\e844'; } /* '' */
.icon-suitcase-2:before { content: '\e845'; } /* '' */
.icon-collaboration:before { content: '\e846'; } /* '' */
.icon-verify-hand:before { content: '\e847'; } /* '' */
.icon-event-clock:before { content: '\e848'; } /* '' */
.icon-certificate-01:before { content: '\e849'; } /* '' */
.icon-event-clock-1:before { content: '\e84a'; } /* '' */
.icon-create:before { content: '\e84b'; } /* '' */
.icon-student:before { content: '\e84c'; } /* '' */
.icon-book:before { content: '\e84d'; } /* '' */
.icon-linkedin:before { content: '\e84e'; } /* '' */
.icon-exchange:before { content: '\e850'; } /* '' */
.icon-info-square:before { content: '\e854'; } /* '' */
.icon-user-default:before { content: '\e85f'; } /* '' */
